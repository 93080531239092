import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/l82SbKOAJoA">
    <SEO title="A Superior Hero - Hebrews" />
  </Layout>
)
export default SermonPost
